.tkci-container {
    .header-container {
        background-image: url("../assets/tkc/tkc_intro.jpg");

        img {
            width: 95%;
            max-width: 350px;
        }
    }

    .panel1 {
        margin: 60px 0;
    }

    .panel2 {
        background-color: #f7f7f7;
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

}
