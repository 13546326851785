.photoday-container {
    .header-container {
        background-image: url("../assets/photoday/photoday_intro.jpg");
        background-position: right;;

        img {
            width: 95%;
            max-width: 500px;
        }
    }

    .panel2, .panel0, .panel5, .panel7, .panel9, .panel11, .panel13 {
        background-color: #f7f7f7;
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2 {
        padding: 60px 0;
    }

    .brand-identity {
        margin-top: 20px;

        .color-container img, .font-container img {
            width: 70%;
        }

        .font-container {
            align-items: flex-end;
        }

        h3 {
            margin-bottom: 20px;
        }
    }

    .panel3 {
        padding: 40px 0;

        .logo-container {
            margin-top: 30px;
        }

        .logo-container img {
            width: 400px;
            margin-top: 20px;
        }

        h2 {
            margin-bottom: 20px;
        }
    }

    .panel4 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 100%;
            text-align: center;
        }
    }

    .panel5 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 90%;
            text-align: center;
        }
    }

    .panel0, .panel6, .panel7, .panel8, .panel9, .panel10, .panel11, .panel12, .panel13 {
        padding: 60px 0;

        img {
            width: 95%;
        }

        h2 {
            margin-bottom: 30px;
        }

    }

    .panel9 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 90%;
            text-align: center;
        }

        .half-div{
            margin: 0 auto;
            text-align: center;

            img {
                width: auto;
                height: 500px;
            }
        }
    }

    .panel14 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        .half-div{

            img {
                width: 100%;
            }
        }

    }
    
}
