.lt-container {
    .header-container {
        background-image: url("../assets/liz-team/lizteam_intro.jpg");

        img {
            width: 95%;
            max-width: 450px;
        }
    }

    .panel2, .panel4, .panel6 {
        background-color: #f7f7f7;
    }

    .panel1 {
        margin: 60px 0;
    }

    .panel2, .panel3, .panel4, .panel7 {
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

    .panel5 {
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 60%;
            border: 1px solid #e2e2e2;
        }
    }

    .panel6 {
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
            border: 1px solid #e2e2e2;
        }
    }

}
