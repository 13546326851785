.bft-container {
    .header-container {
        background-image: url("../assets/bft/b4t_intro.jpg");

        img {
            width: 95%;
            max-width: 450px;
        }
    }

    .panel2, .panel4, .panel6 {
        background-color: #f7f7f7;
    }

    .panel1 {
        margin: 60px 0;

        img {
            width: 95%;
        }
    }

    .panel2 {
        padding: 40px 0;

        .logo-container {
            margin-top: 30px;
        }

        .logo-container img {
            width: 400px;
        }
    }

    .panel3, .panel4, .panel5, .panel6, .panel7 {
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

}
