.illo-container {
    .header-container {
        background-image: url("../assets/illo/illustrations_intro.jpg");

        img {
            width: 95%;
            max-width: 350px;
        }
    }

    .panel1 {
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

}
