.leslie-container {
    .header-container {
        background-image: url("../assets/leslie-hielema/leslie_intro.jpg");

        img {
            width: 95%;
            max-width: 350px;
        }
    }

    .panel1 {
        margin: 60px 0;
    }

    .panel2 {
        padding: 40px 0;
        background-color: #f7f7f7;

        .logo-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .logo-container img {
            width: 400px;
            margin-top: 20px;
        }

        h2 {
            margin-bottom: 20px;
        }
    }

    .panel3 {
        padding: 60px 0;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
            border: 1px solid #e2e2e2;
        }
    }

}
