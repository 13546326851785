.memory-mates-container {
    .header-container {
        background-image: url("../assets/memory-mates/memory_mates_intro.jpg");

        img {
            width: 95%;
            max-width: 450px;
        }
    }

    .panel1 {
        margin: 60px 0;
    }

    .panel2 {
        padding: 60px 0;
        background-color: #f7f7f7;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 75%;
        }
    }

}
