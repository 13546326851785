.buna-container {
    .header-container {
        background-image: url("../assets/buna/buna_intro.jpg");
        background-position: right;;

        img {
            width: 95%;
            max-width: 400px;
        }
    }

    .panel2, .panel4 {
        background-color: #f7f7f7;
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2 {
        padding: 60px 0;
    }

    .brand-identity {
        margin-top: 20px;

        .color-container img, .font-container img {
            width: 70%;
        }

        .font-container {
            align-items: flex-end;
        }

        h3 {
            margin-bottom: 20px;
        }
    }

    .panel3 {
        padding: 40px 0;

        .logo-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .logo-container img {
            width: 400px;
            margin-top: 20px;
        }

        h2 {
            margin-bottom: 20px;
        }

        .logo-prototypes {
            margin-top: 30px;
        }

        .logo-prototypes .full-div{
            width: 100%;
        }

        .logo-prototypes img{
            width: 100%;
        }
    }

    .panel4, .panel5 {
        padding: 60px 0;

        img {
            width: 95%;
        }

        h2 {
            margin-bottom: 30px;
        }

    }

    .panel5 {
        .full-div, .full-div img{
            width: 100%;
        }
    }
    
}
