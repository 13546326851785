.fcb-container {
    .header-container {
        background-image: url("../assets/fcb/fcb_intro.jpg");
        background-position: right;;

        img {
            max-height: 300px;
        }
    }

    .panel2, .panel4, .panel6 {
        background-color: #f7f7f7;
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2 {
        padding: 60px 0;

        img {
            height: 200px;
            margin-top: 20px;
        }
    }

    .panel3, .panel4, .panel5, .panel6 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

    .panel7 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            max-width: 350px;
            width: 100%;
        }
    }

}
