.reedy-container {
    .header-container {
        background-image: url("../assets/reedy/reedy_intro.jpg");

        img {
            width: 95%;
            max-width: 450px;
        }
    }

    .panel1 {
        margin: 60px 0;
    }

    .panel2 {
        padding: 40px 0;
        background-color: #f7f7f7;

        .flex-container:last-child {
            align-items: flex-end;
        }

        .old-logo {
            width: 150px;
        }

        .new-logo {
            width: 90%;
        }
    }

    .panel3, .panel5 {
        margin: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

    .panel4 {
        padding: 60px 0;
        background-color: #f7f7f7;
        
        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

}
