.home-container {
    .addy {
      margin-top: 20px;
    }
    
    .text-container {
        max-width: 450px;
        padding-top: 160px;
        z-index: 2;
        position: relative;
    }

    h1 {
        margin-bottom: 10px;
    }

    .colored-line {
        background-color: #f6926f;
        display: block;
        width: 250px;
        height: 10px;
        margin-bottom: 30px;
    }

    .panel1 {
        position: relative;
        padding-bottom: 80px;

        button {
            margin-top: 20px;
        }
    }

    .panel1-bg {
        background-color: #f7f7f7;
        background-image: url("../assets/home-image.jpg");
        background-position: center;
        background-size: cover;;
        width: 70%;
        height: 700px;
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
    }

    .panel2 {
        padding: 30px 0;
        text-align: center;
        background-color: #f7f7f7;

        .colored-line {
          background-color: #f6926f;
          display: block;
          width: 70px;
          height: 10px;
          margin: 0 auto 30px auto;
        }

        p {
          max-width: 600px;
          text-align: center;
          margin: 0 auto;
        }
    }

    .projects {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 40px;;
    }

    .project-item {
        min-width: 300px;
        width: 49%;
        height: 300px;
        margin-bottom: 20px; 
        background-size: cover;
        background-position: center;
        overflow: hidden;

        h3 {
            text-align: left;
            margin-bottom: 0;
        }
    }

    .project-item:nth-child(odd) {
      margin-right: 10px;
    }

    .project-categories {
        display: none;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 13px;
        width: 70%;
    }

    .project-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transition: all .2s ease-in-out;
        text-decoration: none;
      }
      
      .project-img:hover {
        background-color: rgba(183,229,237,0.8);
        transform: scale(1.1);

        .project-categories {
          display: block;
        }
      }
      
      .project-img img {
        max-height: 160px;
        padding: 20px;
      }

      //ATLS
      .project-item.atls {
        background-image: url("../assets/atls/atls_intro.jpg");
      }
      
      .project-item.atls img {
        width: 300px;
      }

      //BFT
      .project-item.b4t {
        background-image: url("../assets/bft/b4t_intro.jpg");
      }
      
      .project-item.b4t img {
        width: 300px;
      }

      //BUNA
      .project-item.buna {
        background-image: url("../assets/buna/buna_intro.jpg");
      }

      //DISTI
      .project-item.disti {
        background-image: url("../assets/disti/disti-intro.jpg");
      }

      .project-item.disti img {
        max-height: auto;
        max-width: 300px;
        width: 80%;
        }

      //FCB
      .project-item.fcb{
        background-image: url("../assets/fcb/fcb_intro.jpg");
      }

      //GMTM
      .project-item.gmtm{
        background-image: url("../assets/gmtm/gmtm_intro.jpg");
      }

      .project-item.gmtm img {
        width: 250px;
        height: auto;
        max-height: initial;
      }

      // ILLO
      .project-item.illustrations {
        background-image: url("../assets/illo/illustrations_intro.jpg");
      }
      
      .project-item.illustrations img {
        width: 300px;
      }

      // ITO
      .project-item.ito {
        background-image: url("../assets/ito/ito_intro.jpg");
        }
        
        .project-item.ito img {
        max-height: auto;
        max-width: 200px;
        width: 80%;
        }

        // LESLIE HIELEMA
      .project-item.leslie {
        background-image: url("../assets/leslie-hielema/leslie_intro.jpg");
      }
      
      .project-item.leslie img {
        width: 300px;
      }
      
      // LIZ TEAM
      .project-item.liz-team {
        background-image: url("../assets/liz-team/lizteam_intro.jpg");
      }
      
      .project-item.liz-team img {
        width: 300px;
      }
      
      // MEMORY MATES
      .project-item.memory-mates {
        background-image: url("../assets/memory-mates/memory_mates_intro.jpg");
      }
      
      .project-item.memory-mates img {
        width: 300px;
      }

      // METALOCATOR
      .project-item.metalocator {
      background-image: url("../assets/metalocator/metalocator-intro.jpg");
      }
      
      .project-item.metalocator img {
      max-height: auto;
      max-width: 300px;
      width: 80%;
      }
        
      
      // NHFK
      .project-item.nhfk {
        background-image: url("../assets/nhfk/nhfk_intro.jpg");
      }
      
      .project-item.nhfk img {
        max-height: auto;
        max-width: 300px;
        margin-top: 30px;
        width: 80%;
      }

      // PHOTODAY
      .project-item.photoday {
        background-image: url("../assets/photoday/photoday_intro.jpg");
      }
      
      .project-item.photoday img {
        width: 250px;
      }

       
        
        //REEDY
        .project-item.reedy {
        background-image: url("../assets/reedy/reedy_intro.jpg");
        }
        
        .project-item.reedy img {
        max-height: auto;
        max-width: 340px;
        width: 80%;
        }

      //SUFF
      .project-item.suff {
        background-image: url("../assets/suff/suff-intro.jpg");
      }

      .project-item.suff img {
        width: 300px;
      }

      //SUSKI KATANA
      .project-item.sushi-katana {
        background-image: url("../assets/sushi-katana/sk_intro.jpg");
      }
      
      // TKC
      .project-item.tkc {
        background-image: url("../assets/tkc/tkc_intro.jpg");
      }
      
      .project-item.tkc img {
        width: 180px;
      }

      // TEACHBOOST
      .project-item.teachboost {
      background-image: url("../assets/teachboost/teachboost-intro.jpg");
      background-position: right;
      }
      
      .project-item.teachboost img {
      max-height: auto;
      max-width: 290px;
      width: 80%;
      }

      // UCF
      .project-item.ucf {
        background-image: url("../assets/ucf/ucf_intro.jpg");
      }
      
      .project-item.ucf img {
        width: 350px;
      }

      //WANDERIAN
      .project-item.wanderian {
        background-image: url("../assets/wanderian/wanderian_intro.jpg");
      }

      .panel3 {
        margin: 30px 0;
        text-align: center;

        h2 {
          margin-bottom: 0;
        }

        p {
          padding-top: 0;
          margin-bottom: 20px;
        }

        .colored-line {
          background-color: #f6926f;
          display: block;
          width: 90px;
          height: 10px;
          margin: 20px auto 30px auto;
        }
      }
    
      
      
      
      
} //End home container