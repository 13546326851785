.disti-container {
    .header-container {
        background-image: url("../assets/disti/disti-intro.jpg");

        img {
            width: 80%;
            max-width: 500px;
        }
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2 {
        background-color: #f7f7f7;
        padding: 60px 0;

        .logo-container img {
            max-width: 400px;
            width: 95%;
            margin-top: 30px;
        }

        .logo-prototypes {
            margin-top: 30px;
        }

        .logo-prototypes img{
            width: 95%;
        }
    }

    .panel3 {
        padding: 60px 0 40px 0;

        .tradeshow-container {
            margin-top: 30px;

            img {
                height: 500px;
                padding: 0 40px 0px 0;
            }

            p {
                margin-bottom: 40px;
            }
        }

        .third-div, .half-div {
            img {
                width: 98%;
                padding: 0 40px 20px 0;
            }
        }

        
    }

    .panel4 {
        background-color: #f7f7f7;
        padding: 60px 0;

        .one-sheets {
            margin-top: 30px;
        }

        img {
            width: 90%;
        }
    }

    .panel5 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 90%;
        }
    }

    .panel6 {
        background-color: #f7f7f7;
        padding: 60px 0;

        .disti-videos {
            margin-top: 30px;
        }

        video {
            width: 90%;
        }
    }

    .panel7 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

    .panel8 {
        background-color: #f7f7f7;
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

}

@media only screen and (max-width: 560px) {
    .disti-container{
        .tradeshow-container {
            div:first-child img {
                height: auto;
                width: 100%;
            }
        }
    }
}
