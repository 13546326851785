.ucf-container {
    .header-container {
        background-image: url("../assets/ucf/ucf_intro.jpg");

        img {
            width: 95%;
            max-width: 450px;
        }
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2, .panel3 {
        margin: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

}
