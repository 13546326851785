.sk-container {
    .header-container {
        background-image: url("../assets/sushi-katana/sk_intro.jpg");
        background-position: right;;

        img {
            max-height: 300px;
        }
    }

    .panel2, .panel4 {
        background-color: #f7f7f7;
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2 {
        padding: 60px 0;

        img {
            height: 200px;
            margin-top: 20px;
        }
    }

    .panel3{
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

    }

    .panel4, .panel5 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

    .panel3, .panel6 {
        .full-div, .full-div img{
            width: 100%;
        }
    }

    .panel5 {
        .full-div, .full-div img{
            width: 80%;
        }
    }
    
}
