.metalocator-container {
    .header-container {
        background-image: url("../assets/metalocator/metalocator-intro.jpg");
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel2 {
        background-color: #f7f7f7;
        padding: 60px 0;
    }

    .panel3 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 90%;
            border: 1px solid #e2e2e2;
        }

        .flex-container {
            justify-content: center;
            align-items: flex-start;
        }
    }

    .panel4 {
        background-color: #f7f7f7;
        padding: 60px 0;


        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
            border: 1px solid #e2e2e2;
        }
    }

    .panel5 {
        padding: 60px 0;


        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
            border: 1px solid #e2e2e2;
        }
    }
}
