.suff-container {
    .header-container {
        background-image: url("../assets/suff/suff-intro.jpg");
    }

    .panel1 {
        margin: 60px 0;
    }

    .panel2 {
        background-color: #f7f7f7;
        padding: 60px 0;
    }

    .brand-identity {
        margin-top: 20px;

        .color-container img, .font-container img {
            width: 70%;
        }

        .font-container {
            align-items: flex-end;
        }
    }

    .panel3 {
        padding: 40px 0;

        .logo-container {
            margin-top: 30px;
        }

        .logo-container img {
            width: 400px;
        }

        h2 {
            margin-bottom: 30px;
        }
    }

    .panel4 {
        background-color: #f7f7f7;
        padding: 60px 0;

        img {
            width: 90%;
        }
    }

    .panel5 {
        padding: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 90%;
            border: 1px solid #e2e2e2;
        }

        .flex-container {
            justify-content: center;
            align-items: flex-start;
        }
    }
}
