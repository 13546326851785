.gmtm-container {
    .header-container {
        background-image: url("../assets/gmtm/gmtm_intro.jpg");

        img {
            width: 95%;
            max-width: 450px;
        }
    }

    .panel1 {
        margin: 60px 0 40px 0;
    }

    .panel3 img {
        border: 1px solid #e2e2e2;
    }


    .panel2, .panel3, .panel4, .panel5, .panel6 {
        margin: 60px 0;

        h2 {
            margin-bottom: 30px;
        }

        img {
            width: 95%;
        }
    }

    .panel2, .panel4 {
        background-color: #f7f7f7;
    }

    .panel4 {
        img {
            max-width: 300px;
        }
    }

    .panel5, .panel6 {

        img {
            max-width: 450px;
        }
    }

}
