@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
    padding: 0;
    margin: 0;
}

div {
    box-sizing: border-box;
}

h1, h2, h3, button {
    font-family: 'montserratbold', sans-serif;
}

p, a, li, span {
    font-family: 'montserratregular', sans-serif;
}

p {
    line-height: 26px;
    color: #696b72;
}

.centered-container {
    padding: 20px;
    width: 100%;
    max-width: 1080px;
    margin: auto;
}

.nav-container {
    z-index: 9999;
    width: 100%;
    min-width: 100%;
    position: fixed;
    background-color: #fff;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    img {
        height: 80px;
    }

    .nav-items a {
        margin-left: 40px;
        color: #696b72;
        text-decoration: none;
    }
}

button {
    border: none;
    background-color: #b7e5ed;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
}

.footer {
    background-color: #f7f7f7;
    
    p, a {
        color: #696b72;
        font-size: 13px;
        margin: 0 10px;
        text-decoration: none;
        padding: 0;
    }

    .centered-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.header-container {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 500px;
    overflow: hidden;
    display: block;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-container {
    h2 {
        margin-bottom: 0;
        padding-bottom: 0px;
        margin-top: 0;
        padding-top: 0;
    }

    h3 {
        padding-top: 0px;
        margin-top: 10px;
        color: #acaeb1;
        font-size: 15px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .panel1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
            max-width: 450px;
        }

        .half-div:first-child {
            padding-right: 20px;
        }
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
    }

    .half-div {
        width: 50%;
        padding-bottom: 20px;
    }

    .third-div {
        width: 32%;
    }

    .full-div {
        width: 80%;
        min-width: 400px;
        text-align: center;
        margin: 0 auto;
    }

    button {
        margin-top: 30px;
        cursor: pointer;
    }

    .swiper-button-next, .swiper-button-prev {
        color: #c0c2c3;
        top: 100px;
    }

    .swiper-slide .half-div, .swiper-slide .third-div {
        text-align: center;
    }
}

@media only screen and (max-width: 940px) {
    .home-container .project-item {
      width: 100%;
    }
}

@media only screen and (max-width: 830px) {
    .home-container {

      .text-container {
          max-width: 100%;
          margin-bottom: 20px;
      }

      .panel1-bg {
        position: initial;
        width: 100%;
        height: 400px;
      }
    }

    .project-container {
        .full-div, .half-div, .third-div {
            width: 100%;
        }
        
    }
}